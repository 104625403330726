import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {v4 as uuidv4} from "uuid";

let oid = localStorage.getItem("oid");
if (!oid || oid.length != 36) {
    oid = uuidv4();
}
localStorage.setItem('oid', oid);

//全局守卫
router.beforeEach((to, from, next) => {
    if (to.name.requiresAuth) {
        if (localStorage.getItem("token")) {
            window.location.href = '/';
        } else {
            next();
        }
    } else {
        next();
    }
});

createApp(App).use(store).use(router).mount('#app')
