<template>
    <div v-if="recommend_style.hide" class="kalles-section nt_section type_collection_list">
        <div class="container">
            <div class="mt__30 nt_cats_holder row equal_nt hoverz_true cat_size_5 cat_lay4_2 cat_lay5_5 cat_space_30 cat_design_1">
                <div v-for="(dt2, index2) in recommend_style.home_recommends" :key="index2" class="cat_grid_item cat_space_item cat_grid_item_1 col-md-3 col-3">
                    <div class="cat_grid_item__content position-relative oh">
                        <a :href="dt2.url" target="_blank" class="d-block cat_grid_item__link">
                            <div class="cat_grid_item__overlay item__position nt_bg_lz lazyload top" :data-bgset="dt2.img_url ? dt2.img_url : '/img/placeholders/270-300.jpg'"></div>
                        </a>
                        <div v-if="false" class="cat_grid_item__wrapper pe_none">
                            <div class="cat_grid_item__title">women</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RecommendStyle5",
    props: ['recommend_style'],
}
</script>

<style scoped>

</style>