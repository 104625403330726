import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Icons.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/product',
        redirect: '/product/list'
    },
    {
        path: '/product/list',
        name: 'Product',
        component: () => import('../views/product/List.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/product/list/:id',
        name: 'ProductList',
        component: () => import('../views/product/List.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/product/item/:id',
        name: 'ProductItem',
        component: () => import('../views/product/Item.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/article',
        redirect: '/article/list'
    },
    {
        path: '/article/list',
        name: 'Article',
        component: () => import('../views/article/List.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/article/list/:id',
        name: 'ArticleList',
        component: () => import('../views/article/List.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/article/item/:id',
        name: 'ArticleItem',
        component: () => import('../views/article/Item.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/single/:id',
        name: 'Single',
        component: () => import('../views/single/Single.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/my/like',
        name: 'MyLike',
        component: () => import('../views/my/Like.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('../views/Icons.vue'),
        meta: {
            requiresAuth: true
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
