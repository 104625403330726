<template>
    <HeadBanner v-if="false" />

    <router-view/>

    <!--mask overlay-->
    <div class="mask-overlay ntpf t__0 r__0 l__0 b__0 op__0 pe_none"></div>
    <!--end mask overlay-->

    <!-- search box -->
    <div id="nt_search_canvas" class="nt_fk_canvas dn">
        <div class="nt_mini_cart flex column h__100">
            <div class="mini_cart_header flex fl_between al_center">
                <h3 class="widget-title tu fs__16 mg__0 font-poppins">搜索产品</h3>
                <i class="close_pp pegk pe-7s-close ts__03 cd"></i></div>
            <div class="mini_cart_wrap">
                <div class="search_header mini_search_frm pr js_frm_search" role="search">
                    <div class="frm_search_cat mb__20">
                        <select name="product_type" v-model="options.search_result.product_category_id">
                            <option value="">全部分类</option>
                            <option v-for="(dt, index) in product_category_list" :key="index" :value="dt.product_category_id">{{dt.title}}</option>
                        </select>
                    </div>
                    <div class="frm_search_input pr oh">
                        <input class="search_header__input js_iput_search placeholder-black" @keyup.enter="clickSearch()" v-model="options.search_result.title" autocomplete="off" type="text" name="q" placeholder="搜索产品" />
                        <button class="search_header__submit js_btn_search" type="button" @click="clickSearch()"><i class="iccl iccl-search"></i></button>
                    </div>
                    <div class="ld_bar_search"></div>
                </div>
                <div class="search_header__prs fwsb cd">
                    <span class="h_suggest">{{options.search_result.show_search_header ? '搜索结果:' : '推荐产品'}}</span>
                    <span class="h_result dn">搜索结果</span>
                    <span class="h_results dn">搜索结果</span>
                </div>
                <div class="search_header__content mini_cart_content fixcl-scroll widget">
                    <div class="fixcl-scroll-content product_list_widget">
                        <div class="js_prs_search">
                            <p v-if="options.search_result.list.length == 0">没找到相关产品</p>
                            <template v-else>
                                <div v-for="(dt, index) in options.search_result.list" :key="index" class="row mb__10 pb__10">
                                    <div class="col widget_img_pr"><a class="db pr oh" :href="'/product/item/' + dt.product_id" target="_blank"><img src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%201200%201200%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C%2Fsvg%3E" class="w__100 lazyload" :alt="dt.title" :data-src="dt.scene_url" width="80" height="80" /></a></div>
                                    <div class="col widget_if_pr">
                                        <a class="product-title db" :href="'/product/item/' + dt.product_id" target="_blank">{{dt.title}}</a>
                                        <template v-if="website.show_price">
                                            <span style="font-size: 12px;">¥</span>{{dt.min_price}}
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <a href="/product/list" class="btn fwsb detail_link">全部产品<i class="las la-arrow-right fs__18"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end search box -->

    <!-- mobile menu -->
    <div id="nt_menu_canvas" class="nt_fk_canvas nt_sleft dn lazyload"><i class="close_pp pegk pe-7s-close ts__03 cd"></i>
        <div class="mb_nav_tabs flex al_center mb_cat_true">
            <div class="mb_nav_title pr mb_nav_ul flex al_center fl_center active" data-id="#kalles-section-mb_nav_js"><span class="db truncate">菜单</span></div>
            <div class="mb_nav_title pr flex al_center fl_center" data-id="#kalles-section-mb_cat_js"><span class="db truncate">产品分类</span></div>
        </div>
        <div id="kalles-section-mb_nav_js" class="mb_nav_tab active">
            <div id="kalles-section-mb_nav" class="kalles-section">
                <ul id="menu_mb_ul" class="nt_mb_menu">
                    <template v-for="(dt, index) in nav_tree" :key="index">
                        <li v-if="dt.position == 3" :class="['menu-item', {'menu-item-has-children only_icon_false': dt.children && dt.children.length > 0}]">
                            <a v-if="dt.children && dt.children.length > 0" :href="dt.url" :target="dt.blank ? '_blank' : '_self'"><span class="nav_link_txt flex al_center">{{dt.title}}</span><span class="nav_link_icon ml__5"></span></a>
                            <a v-else :href="dt.url" :target="dt.blank ? '_blank' : '_self'">{{dt.title}}</a>
                            <ul v-if="dt.children && dt.children.length > 0" class="sub-menu">
                                <li v-for="(dt2, index2) in dt.children" :key="index2" class="menu-item"><a :href="dt2.url" :target="dt.blank ? '_blank' : '_self'">{{dt2.title}}</a></li>
                            </ul>
                        </li>
                    </template>
                    <li class="menu-item menu-item-btns menu-item-sea push_side" data-id="#nt_search_canvas"><a href="#"><span class="iconbtns">搜索</span></a></li>

                    <template v-if="false">
                        <template v-if="my">
                            <li class="menu-item menu-item-btns menu-item-wishlist"><a class="js_link_wis" href="/my/like"><span class="iconbtns">喜欢的产品</span></a></li>
                            <li class="menu-item menu-item-btns menu-item-logout"><a @click.prevent="clickLogout()" class="js_link_wis" href="#"><span class="iconbtns">退出</span></a></li>
                        </template>
                        <li v-else class="menu-item menu-item-btns menu-item-acount"><a href="#" class="push_side" data-id="#nt_login_canvas"><span class="iconbtns">登录 / 注册</span></a></li>
                    </template>

                    <li class="menu-item menu-item-infos">
                        <p class="menu_infos_title">联系我们</p>
                        <div class="menu_infos_text"><i class="pegk pe-7s-call fwb mr__10"></i><a :href="'tel:' + website.hotline">{{website.hotline}}</a><br>
                            <i class="pegk pe-7s-mail fwb mr__10"></i><a class="cg" :href="'mailto:' + website.email">{{website.email}}</a></div>
                    </li>
                    <li class="menu-item menu-item-has-children only_icon_false currencies">
                        <a href="#"><span class="current dib flagst4 flagst4-sm flagst4-CN lazyload">简体中文</span><span class="nav_link_icon ml__5"></span></a>
                        <ul class="sub-menu">
                            <li><a class="currency-item nt-currency-flag--cn--w16-h-12 cg db selected" href="#">简体中文</a></li>
                            <li v-if="false"><a class="currency-item nt-currency-flag--usd--w16-h-12 cg db" href="#">English</a></li>
                            <li v-if="false"><a class="currency-item nt-currency-flag--jpy--w16-h-12 cg db" href="#">日本語</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div id="kalles-section-mb_cat_js" class="mb_nav_tab">
            <div id="kalles-section-mb_cat" class="kalles-section">
                <ul id="menu_mb_cat" class="nt_mb_menu">
                    <li v-for="(dt, index) in product_category_list" :key="index" class="menu-item"><a :href="'/product/list/' + dt.product_category_id"><i class="las la-gem mr__10 fs__20"></i>{{dt.title}}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- end mobile menu -->

    <!-- login box -->
    <div v-if="!my" id="nt_login_canvas" class="nt_fk_canvas dn lazyload">
        <form id="customer_login" class="nt_mini_cart flex column h__100 is_selected">
            <div class="mini_cart_header flex fl_between al_center">
                <div class="h3 widget-title tu fs__16 mg__0">登录</div>
                <i class="close_pp pegk pe-7s-close ts__03 cd"></i>
            </div>
            <div class="mini_cart_wrap">
                <div class="mini_cart_content fixcl-scroll">
                    <div class="fixcl-scroll-content">
                        <p class="form-row">
                            <label for="CustomerAccount">账号 <span class="required">*</span></label>
                            <input type="text" v-model="options.login_data.account" name="account" id="CustomerAccount" autocomplete="account" autocapitalize="off" />
                        </p>
                        <p class="form-row">
                            <label for="CustomerPassword">密码 <span class="required">*</span></label>
                            <input type="password" v-model="options.login_data.password" name="password" autocomplete="current-password" id="CustomerPassword" />
                        </p>
                        <input type="submit" @click.prevent="clickLoginSubmit()" class="button button_primary w__100 tu js_add_ld" value="登录" />
                        <br>
                        <p class="mb__10 mt__20">没有帐号？ <a href="#" data-id="#RegisterForm" class="link_acc">点击注册</a></p>
                        <p>忘记密码？ <a href="#" data-id="#RecoverForm" class="link_acc">找回密码</a></p>
                    </div>
                </div>
            </div>
        </form>
        <form id="RecoverForm" class="nt_mini_cart flex column h__100">
            <div class="mini_cart_header flex fl_between al_center">
                <div class="h3 widget-title tu fs__16 mg__0">找回密码</div>
                <i class="close_pp pegk pe-7s-close ts__03 cd"></i>
            </div>
            <div class="mini_cart_wrap">
                <div class="mini_cart_content fixcl-scroll">
                    <div class="fixcl-scroll-content">
                        <p class="form-row">
                            <label for="RecoverEmail">邮箱</label>
                            <input type="email" v-model="options.reset_password_data.email" name="email" id="RecoverEmail" class="input-full" autocomplete="email" autocapitalize="off" />
                        </p>
                        <input type="submit" @click.prevent="clickResetPasswordSubmit()" class="button button_primary w__100 tu js_add_ld" value="重置密码" />
                        <br>
                        <p class="mb__10 mt__20">想起密码？ <a href="#" data-id="#customer_login" class="link_acc">去登录</a></p>
                    </div>
                </div>
            </div>
        </form>
        <form id="RegisterForm" class="nt_mini_cart flex column h__100">
            <div class="mini_cart_header flex fl_between al_center">
                <div class="h3 widget-title tu fs__16 mg__0">注册</div>
                <i class="close_pp pegk pe-7s-close ts__03 cd"></i>
            </div>
            <div class="mini_cart_wrap">
                <div class="mini_cart_content fixcl-scroll">
                    <div class="fixcl-scroll-content">
                        <p class="form-row">
                            <label for="username">用户名 <span class="required">*</span></label>
                            <input type="text" v-model="options.register_data.username" name="username" id="username" autocomplete="username" aria-required="true" />
                        </p>
                        <p class="form-row">
                            <label for="phone">手机 <span class="required">*</span></label>
                            <input type="text" v-model="options.register_data.phone" name="phone" id="phone" autocomplete="phone" aria-required="true" />
                        </p>
                        <p class="form-row">
                            <label for="-email">邮箱 <span class="required">*</span></label>
                            <input type="email" v-model="options.register_data.email" name="email" id="-email" autocapitalize="off" autocomplete="email" aria-required="true" />
                        </p>
                        <p class="form-row">
                            <label for="-password">密码 <span class="required">*</span></label>
                            <input type="password" v-model="options.register_data.password" name="password" id="-password" autocomplete="current-password" aria-required="true" />
                        </p>
                        <p class="form-row">
                            <label for="confirm_password">确认密码 <span class="required">*</span></label>
                            <input type="password" v-model="options.register_data.confirm_password" name="confirm_password" id="confirm_password" autocomplete="current-password" aria-required="true" />
                        </p>
                        <input type="submit" @click.prevent="clickRegisterSubmit()" value="注册" class="button button_primary w__100 tu js_add_ld" />
                        <br>
                        <p class="mb__10 mt__20">已经有账户？ <a href="#" data-id="#customer_login" class="link_acc">登录</a></p>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- end login box -->

    <!-- mobile toolbar -->
    <div id="kalles-section-toolbar_mobile" class="kalles-section">
        <div class="kalles_toolbar kalles_toolbar_label_true ntpf r__0 l__0 b__0 flex fl_between al_center">
            <div class="type_toolbar_shop kalles_toolbar_item"><a href="/product/list"><span class="toolbar_icon"></span><span class="kalles_toolbar_label">产品</span></a></div>
            <template v-if="false">
                <div v-if="false" class="type_toolbar_filter kalles_toolbar_item"><a class="dt_trigger_cl" href="#" data-trigger=".btn_filter"><span class="toolbar_icon"></span><span class="kalles_toolbar_label">赛选</span></a></div>
                <div class="type_toolbar_wish kalles_toolbar_item"><a class="js_link_wis" href="/my/like"><span class="toolbar_icon"><span class="jswcount toolbar_count">3</span></span><span class="kalles_toolbar_label">喜欢</span></a></div>
                <div v-if="false" class="type_toolbar_cart kalles_toolbar_item"><a href="#" class="push_side" data-id="#nt_cart_canvas"><span class="toolbar_icon"><span class="jsccount toolbar_count">5</span></span><span class="kalles_toolbar_label">购物车</span></a></div>
                <div v-if="!my" class="type_toolbar_account kalles_toolbar_item"><a href="#" class="push_side" data-id="#nt_login_canvas"><span class="toolbar_icon"></span><span class="kalles_toolbar_label">账号</span></a></div>
            </template>
            <div class="type_toolbar_search kalles_toolbar_item"><a href="#" class="push_side" data-id="#nt_search_canvas"><span class="toolbar_icon"></span><span class="kalles_toolbar_label">搜索</span></a></div>
        </div>
    </div>
    <!-- end mobile toolbar -->

    <!-- back to top button-->
    <a id="nt_backtop" class="pf br__50 z__100 des_bt1" href="#"><span class="tc br__50 db cw"><i class="pr pegk pe-7s-angle-up"></i></span></a>
</template>

<script>
import HeadBanner from '@/components/HeadBanner.vue'
import {getCurrentInstance, provide, reactive, ref, watch} from "vue";
import {getWebsiteV1Api} from "@/api/website/website";
import {listNavV1Api} from "@/api/website/nav";
import {xsListArticleCategoryV1Api} from "@/api/article/article-category";
import {listProductCategoryV1Api} from "@/api/product/product-category";
import {xsListSingleV1Api} from "@/api/single/single";
import {listProductV1Api} from "@/api/product/product";
import {getMyV1Api, authMyV1Api, registerMyV1Api} from "@/api/user/my";

export default {
    name: 'Home',
    components: {HeadBanner},
    setup() {
        const {proxy} = getCurrentInstance();

        const options = reactive({
            title: 'App',
            search_result: {
                title: '',
                show_search_header: false,
                product_category_id: '',
                page: 1,
                page_size: 10,
                list: [],
            },
            reset_password_data: {
                email: '',
            },
            login_data: {
                account: '',
                password: '',
            },
            register_data: {
                username: '',
                phone: '',
                email: '',
                password: '',
                confirm_password: '',
            },
        });

        // 实现无限层级树形数据结构
        const treeData = (source, id, parentId, children) => {
            // 对源数据深度克隆
            let cloneData = JSON.parse(JSON.stringify(source));
            return cloneData.filter(father => {
                // 返回每一项的子级数组
                let branchArr = cloneData.filter(child => father[id] == child[parentId]);
                // 如果存在子级，则给父级添加一个children属性，并赋值
                branchArr.length > 0 ? father[children] = branchArr : '';
                //返回第一层 (如果第一层不是parentId=0，请自行修改)
                return father[parentId] == 0;
            })
        }
        // 调用时，字段名以字符串的形式传参，如 treeData(source, 'id', 'parentId', 'children');

        const website = ref({
            name: '',
            full_name: '',
            host: '',
            logo: '',
            logo_icon: '',
            slogan: '',
            keywords: '',
            description: '',
            hotline: '',
            telephone: '',
            email: '',
            province: '',
            city: '',
            district: '',
            town: '',
            address: '',
            icp: '',
            working_hours: '',
            copyright: '',
            show_price: false,
        });
        provide('website', website);
        const apiWebsite = () => {
            return new Promise(function (resolve) {
                getWebsiteV1Api().then(res => {
                    if (res.data.code == 0) {
                        website.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const article_category_list = ref([]);
        provide('article_category_list', article_category_list);
        const apiArticleCategoryList = () => {
            return new Promise(function (resolve) {
                xsListArticleCategoryV1Api({page: 1, page_size: 10000}).then(res => {
                    if (res.data.code == 0) {
                        article_category_list.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const single_list = ref([]);
        provide('single_list', single_list);
        const apiSingleList = () => {
            return new Promise(function (resolve) {
                xsListSingleV1Api({page: 1, page_size: 10000}).then(res => {
                    if (res.data.code == 0) {
                        single_list.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const product_category_list = ref();
        provide('product_category_list', product_category_list);
        const apiProductCategoryList = () => {
            return new Promise(function (resolve) {
                listProductCategoryV1Api({page: 1, page_size: 10000}).then(res => {
                    if (res.data.code == 0) {
                        product_category_list.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const nav_tree = ref();
        provide('nav_tree', nav_tree);
        const nav_list = ref([]);
        provide('nav_list', nav_list);
        const apiNavList = () => {
            return new Promise(function (resolve) {
                listNavV1Api({hide: true, page: 1, page_size: 1000}).then(res => {
                    if (res.data.code == 0) {
                        nav_list.value = res.data.data;

                        if (nav_list.value && nav_list.value.length > 0) {
                            nav_tree.value = treeData(nav_list.value, 'nav_id', 'parent_id', 'children');
                        }
                    }

                    resolve(true);
                });
            });
        };

        const my = ref();
        provide('my', my);
        const apiMy = () => {
            return new Promise(function (resolve) {
                getMyV1Api().then(res => {
                    if (res.data.code == 0) {
                        my.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const clickLikeAdd = (id) => {
            console.log(id)
        }
        provide('clickLikeAdd', clickLikeAdd);

        const apiProductList = () => {
            return new Promise(function (resolve) {
                listProductV1Api(options.search_result).then(res => {
                    if (res.data.code == 0) {
                        options.search_result.list = res.data.data.list;
                    }

                    resolve(true);
                });
            });
        };
        const clickSearch = () => {
            options.search_result.show_search_header = true;

            apiProductList();
        }

        const clickLoginSubmit = () => {
            if (!options.login_data.account) {
                alert('账号不能为空');
            } else if (!options.login_data.password) {
                alert('密码不能为空');
            } else {
                authMyV1Api(options.login_data).then(res => {
                    if (res.data.code == 0) {
                        localStorage.setItem('oid', res.data.data.open_id);
                        localStorage.setItem('token', res.data.data.token);

                        location.reload();
                    } else {
                        alert(res.data.msg);
                    }
                });
            }
        }

        const clickRegisterSubmit = () => {
            // console.log(options.register_data)
            if (options.register_data.email && options.register_data.password) {
                registerMyV1Api(options.register_data).then(res => {
                    if (res.data.code == 0) {
                        localStorage.setItem('oid', res.data.data.open_id);
                        localStorage.setItem('token', res.data.data.token);

                        location.reload();
                    } else {
                        alert(res.data.msg)
                    }
                });
            }
        }

        const clickResetPasswordSubmit = () => {
            console.log(options.reset_password_data)
        }
        
        // 初始数据加载
        Promise.all([apiWebsite(), apiArticleCategoryList(), apiSingleList(), apiNavList(), apiProductCategoryList(), apiProductList(), apiMy()]).then(() => {
            // mainNotyConfirm.close();

            // apiSingleList().then(() => {
            //     mainNotyConfirm.close();
            // });
        });

        const clickLogout = () => {
            localStorage.removeItem('oid');
            localStorage.removeItem('token');

            // window.location.href = '/login';
            location.reload();
        }
        provide('clickLogout', clickLogout);

        watch(() => proxy.$route, () => {
            // console.log(v)
        });

        return {options, website, product_category_list, nav_tree, my, clickLogout, clickSearch, clickLoginSubmit, clickRegisterSubmit, clickResetPasswordSubmit}
    },
}
</script>

<style>
.flagst4-CN.lazyloaded:before {
    background-image: url('/img/cn.svg');
}
.nt_mb_menu .menu-item-logout > a .iconbtns:before {
    font-family: 'Line Awesome Free';
    content: "\f2f5";
}
</style>
