import axios from '@/http';

/**
 * 用户
 * @type {string}
 */
const cloud = (process.env.VUE_APP_API_CLOUD == 'cloud') ? '/user-service' : '';
const url_prefix = cloud + '/api/public/v1/my';


/**
 * 获取自己
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMyV1Api = () => axios.get(url_prefix + "/get");

/**
 * 登录
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const authMyV1Api = (data) => axios.post(url_prefix + "/auth", data);

/**
 * 注册
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const registerMyV1Api = (data) => axios.post(url_prefix + "/register", data);
