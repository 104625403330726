import axios from '@/http';

/**
 * 首页 RecommendStyle
 * @type {string}
 */
const cloud = (process.env.VUE_APP_API_CLOUD == 'cloud') ? '/home-service' : '';
const url_prefix = cloud + '/api/public/v1/home-recommend-style';


/**
 * 获取（单个）
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRecommendStyleHomeV1Api = (id) => axios.get(url_prefix + "/get?id=" + id);

/**
 * 获取（列表）
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listRecommendStyleHomeV1Api = (data) => axios.post(url_prefix + "/list", data);


