import axios from '@/http';

/**
 * 网站设置
 * @type {string}
 */
const cloud = (process.env.VUE_APP_API_CLOUD == 'cloud') ? '/website-service' : '';
const url_prefix = cloud + '/api/public/v1/website';


/**
 * 获取（单个）
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWebsiteV1Api = () => axios.get(url_prefix + "/get");