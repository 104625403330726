import axios from '@/http';

/**
 * 导航
 * @type {string}
 */
const cloud = (process.env.VUE_APP_API_CLOUD == 'cloud') ? '/website-service' : '';
const url_prefix = cloud + '/api/public/v1/nav';


/**
 * 获取（列表）
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listNavV1Api = (data) => axios.post(url_prefix + "/list", data);