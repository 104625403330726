import axios from '@/http';

/**
 * 产品分类
 * @type {string}
 */
const cloud = (process.env.VUE_APP_API_CLOUD == 'cloud') ? '/product-service' : '';
const url_prefix = cloud + '/api/public/v1/product-category';


/**
 * 获取（单个）
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProductCategoryV1Api = (id) => axios.get(url_prefix + "/get?id=" + id);

/**
 * 获取（列表）
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listProductCategoryV1Api = (data) => axios.post(url_prefix + "/list", data);

/**
 * 获取（简洁列表）
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const xsListProductCategoryV1Api = (data) => axios.post(url_prefix + "/list/xs", data);