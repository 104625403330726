import axios from '@/http';

/**
 * 产品
 * @type {string}
 */
const cloud = (process.env.VUE_APP_API_CLOUD == 'cloud') ? '/product-service' : '';
const url_prefix = cloud + '/api/public/v1/product';


/**
 * 获取（单个）
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProductV1Api = (id) => axios.get(url_prefix + "/get?id=" + id);


/**
 * 获取（单个, 完整）
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getFullProductV1Api = (id) => axios.get(url_prefix + "/fully/get?id=" + id);

/**
 * 获取（列表）
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listProductV1Api = (data) => axios.post(url_prefix + "/list", data);

/**
 * 获取（简洁列表）
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const xsListProductV1Api = (data) => axios.post(url_prefix + "/list/xs", data);

/**
 * 获取导航和搜索框 的产品
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const navSearchProductV1Api = () => axios.post(url_prefix + "/nav-search");

/**
 * 获取推荐首页的产品
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const homeProductV1Api = (data) => axios.post(url_prefix + "/home", data);

/**
 * 获取指定id商品
 * @param ids：id1,id2,id3
 * @returns {Promise<AxiosResponse<any>>}
 */
export const idsProductV1Api = (ids) => axios.post(url_prefix + "/product_ids?ids=" + ids);