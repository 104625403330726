import axios from 'axios'
import {v4 as uuidv4} from "uuid";

const service = axios.create({
    //url = base url + reqeust url
    // baseURL : "http://127.0.0.1:8089",
    // baseURL : "http://192.168.3.16:8089",
    baseURL: process.env.VUE_APP_API_HOST,
    
    //配置请求超时时间
    timeout: 60000 * 5,
    // headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Headers": "X-Requested-With,Content-Type",
    //     "Access-Control-Allow-Methods": "PUT,POST,GET,DELETE,OPTIONS",
    // }
})


// service.defaults.headers.common['token'] = AUTH_TOKEN;

// 请求 拦截器
service.interceptors.request.use(config => {
    let oid = localStorage.getItem("oid");
    if (!oid || oid.length != 36) {
        oid = uuidv4();
    }
    localStorage.setItem('oid', oid);
    config.headers.Oid = oid;

    // 让每个请求携带令牌
    if (localStorage.getItem("token")) {
        config.headers.Authorization = localStorage.getItem("token");//把localStorage的token放在Authorization里
    }
    
    return config;
}, function (err) {
    console.log("失败信息" + err);
});


export default service