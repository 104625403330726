<template>
    <!-- footer -->
    <footer id="nt_footer" class="bgbl footer-1">
        <div id="kalles-section-footer_top" class="kalles-section footer__top type_instagram">
            <div class="footer__top_wrap footer_sticky_false footer_collapse_true nt_bg_overlay pr oh pb__30 pt__80">
                <div class="container pr z_100">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-12 mb__50 order-lg-1 order-1">
                            <div class="widget widget_text widget_logo">
                                <h3 class="widget-title fwsb flex al_center fl_between fs__16 mg__0 mb__30 dn_md"><span class="txt_title">联系我们</span><span class="nav_link_icon ml__5"></span></h3>
                                <div class="widget_footer">
                                    <div class="footer-contact">
                                        <p><a class="d-block" href="/"><img class="w__100 mb__15 lz_op_ef lazyload max-width__95px" src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%20220%2066%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C%2Fsvg%3E" :alt="website.name" data-src="/img/logo.svg" /></a></p>
                                        <p>
                                            <i class="pegk pe-7s-map-marker"></i><span>{{website.province}} {{website.city}} {{website.district}} {{website.town}}<br />
                                            <span class="pl__30">{{website.address}}</span></span>
                                        </p>
                                        <p><i class="pegk pe-7s-mail"></i><span><a :href="'mailto:' + website.email">{{website.email}}</a></span></p>
                                        <p><i class="pegk pe-7s-call"></i><span><a :href="'tel:' + website.hotline">{{website.hotline}}</a><br /><a :href="'tel:' + website.telephone" class="pl__30">{{website.telephone}}</a></span></p>
                                        <div v-if="false" class="nt-social">
                                            <a href="https://www.facebook.com" class="facebook cb ttip_nt tooltip_top"><span class="tt_txt">Follow on Facebook</span><i class="facl facl-facebook"></i></a>
                                            <a href="https://twitter.com" class="twitter cb ttip_nt tooltip_top"><span class="tt_txt">Follow on Twitter</span><i class="facl facl-twitter"></i></a>
                                            <a href="https://www.instagram.com" class="instagram cb ttip_nt tooltip_top"><span class="tt_txt">Follow on Instagram</span><i class="facl facl-instagram"></i></a>
                                            <a href="https://www.linkedin.com" class="linkedin cb ttip_nt tooltip_top"><span class="tt_txt">Follow on Linkedin</span><i class="facl facl-linkedin"></i></a>
                                            <a href="https://www.pinterest.com" class="pinterest cb ttip_nt tooltip_top"><span class="tt_txt">Follow on Pinterest</span><i class="facl facl-pinterest"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-for="(dt, index) in nav_tree" :key="index">
                            <div v-if="dt.position == 4" class="col-lg-2 col-md-6 col-12 mb__50 order-1">
                                <div class="widget widget_nav_menu">
                                    <h3 class="widget-title fwsb flex al_center fl_between fs__16 mg__0 mb__30"><span class="txt_title">{{dt.title}}</span><span class="nav_link_icon ml__5"></span></h3>
                                    <div class="menu_footer widget_footer">
                                        <ul class="menu">
                                            <li v-for="(dt2, index2) in dt.children" :key="index2" class="menu-item"><a :href="dt2.url">{{dt2.title}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="col-lg-3 col-md-6 col-12 mb__50 order-lg-5 order-1">
                            <div class="widget widget_text">
                                <h3 class="widget-title fwsb flex al_center fl_between fs__16 mg__0 mb__30"><span class="txt_title">宝尔通防伪查询</span><span class="nav_link_icon ml__5"></span></h3>
                                <div class="widget_footer newl_des_1" style="background: #FFFFFF; padding: 10px 0;">
                                    <iframe src="//web.qs12315.com/" name="ad" width="100%" height="190" scrolling="No" frameborder="0" id="ad"></iframe>
                                </div>
                                <div v-if="false" class="widget_footer newl_des_1">
                                    <p>Subscribe to our newsletter and get 10% off your first purchase</p>
                                    <form id="contact_form" class="mc4wp-form pr z_100">
                                        <div class="mc4wp-form-fields">
                                            <div class="signup-newsletter-form row no-gutters pr oh ">
                                                <div class="col col_email">
                                                    <input type="email" name="email" placeholder="Your email address" value="" class="tc tl_md input-text" required="required">
                                                </div>
                                                <div class="col-auto">
                                                    <button type="submit" class="btn_new_icon_false w__100 submit-btn truncate"><span>Subscribe</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <p class="mt__20"><img class="w__100 lz_op_ef lazyload w__max-width__197" data-src="/images/payment2.png" src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%20197%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C%2Fsvg%3E" alt=""></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="kalles-section-footer_bot" class="kalles-section footer__bot">
            <div class="footer__bot_wrap pt__20 pb__20">
                <div class="container pr tc">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-12 col_1">
                            <span v-html="website.copyright"></span>
                            <a v-if="website.icp" class="ml__7" href="https://beian.miit.gov.cn/" target="_blank">{{website.icp}}</a>
                        </div>
                        <div class="col-lg-6 col-md-12 col-12 col_2">
                            <ul id="footer-menu" class="clearfix">
                                <template v-for="(dt, index) in nav_tree" :key="index">
                                    <li v-if="dt.position == 6" class="menu-item"><a :href="dt.url">{{dt.title}}</a></li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- end footer -->
</template>

<script>
import {inject} from "vue";

export default {
    name: "Footer",
    setup() {

        const website = inject('website');
        const nav_tree = inject('nav_tree');

        return {website, nav_tree}
    },
}
</script>

<style scoped>

</style>