<template>
    <!--head banner-->
    <div id="kalles-section-header_banner" class="kalles-section-header_banner">
        <div class="h__banner bgp pt__10 pb__10 fs__14 flex fl_center al_center pr oh show_icon_false">
            <div class="container">
                <div class="row al_center">
                    <div class="col-auto op__0"> <a href="#" class="h_banner_close pr pl__10 cw z_index">close</a> </div>
                    <a href="shop.html" class="pa t__0 l__0 r__0 b__0 z_100"></a>
                    <div class="col h_banner_wrap tc cw">Today deal sale off <strong>70% </strong>. End in <strong class="js_kl__countdown" data-date="2021/12/19"></strong>. Hurry Up <i class="las la-arrow-right"></i></div>
                    <div class="col-auto"> <a href="#" class="h_banner_close pr pl__10 cw z_100">close</a> </div>
                </div>
            </div>
        </div>
    </div>
    <!--end head banner-->
</template>

<script>
export default {
    name: "HeadBanner"
}
</script>

<style scoped>

</style>