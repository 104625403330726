<template>
    <div v-if="recommend_style.hide" class="kalles-section nt_section type_collection_list">
        <div class="container">
            <div class="mt__30 nt_cats_holder row equal_nt hoverz_true cat_size_5 cat_lay4_1 cat_lay5_2 cat_space_30 cat_design_1">
                <div class="col-md-6 col-12">
                    <div class="row">
                        <div class="cat_grid_item cat_space_item cat_grid_item_1 col-12">
                            <div class="cat_grid_item__content pr oh">
                                <a :href="recommend_style.home_recommends[0].url" target="_blank" class="db cat_grid_item__link">
                                    <div class="cat_grid_item__overlay item__position nt_bg_lz top lazyload" :data-bgset="recommend_style.home_recommends[0].img_url ? recommend_style.home_recommends[0].img_url : '/img/placeholders/570-570.jpg'"></div>
                                </a>
                                <div v-if="false" class="cat_grid_item__wrapper pe_none">
                                    <div class="cat_grid_item__title">Women{{recommend_style.home_recommends[0].home_recommend_id}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="cat_grid_item cat_space_item cat_grid_item_4 col-6">
                                    <div class="cat_grid_item__content pr oh">
                                        <a :href="recommend_style.home_recommends[3].url" target="_blank" class="db cat_grid_item__link">
                                            <div class="cat_grid_item__overlay item__position nt_bg_lz top lazyload" :data-bgset="recommend_style.home_recommends[3].img_url ? recommend_style.home_recommends[3].img_url : '/img/placeholders/270-300.jpg'"></div>
                                        </a>
                                        <div v-if="false" class="cat_grid_item__wrapper pe_none">
                                            <div class="cat_grid_item__title">Bags{{recommend_style.home_recommends[3].home_recommend_id}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cat_grid_item cat_space_item cat_grid_item_5 col-6">
                                    <div class="cat_grid_item__content pr oh">
                                        <a :href="recommend_style.home_recommends[4].url" target="_blank" class="db cat_grid_item__link">
                                            <div class="cat_grid_item__overlay item__position nt_bg_lz bottom lazyload" :data-bgset="recommend_style.home_recommends[4].img_url ? recommend_style.home_recommends[4].img_url : '/img/placeholders/270-300.jpg'"></div>
                                        </a>
                                        <div v-if="false" class="cat_grid_item__wrapper pe_none">
                                            <div class="cat_grid_item__title">Footwear{{recommend_style.home_recommends[4].home_recommend_id}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="row">
                        <div class="cat_grid_item cat_space_item cat_grid_item_2 col-12">
                            <div class="cat_grid_item__content pr oh">
                                <a :href="recommend_style.home_recommends[1].url" target="_blank" class="db cat_grid_item__link">
                                    <div class="cat_grid_item__overlay item__position nt_bg_lz center lazyload" :data-bgset="recommend_style.home_recommends[1].img_url ? recommend_style.home_recommends[1].img_url : '/img/placeholders/570-300.jpg'"></div>
                                </a>
                                <div v-if="false" class="cat_grid_item__wrapper pe_none">
                                    <div class="cat_grid_item__title">Men{{recommend_style.home_recommends[1].home_recommend_id}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="cat_grid_item cat_space_item cat_grid_item_3 col-12">
                            <div class="cat_grid_item__content pr oh">
                                <a :href="recommend_style.home_recommends[2].url" target="_blank" class="db cat_grid_item__link">
                                    <div class="cat_grid_item__overlay item__position nt_bg_lz center lazyload" :data-bgset="recommend_style.home_recommends[2].img_url ? recommend_style.home_recommends[2].img_url : '/img/placeholders/570-570.jpg'"></div>
                                </a>
                                <div v-if="false" class="cat_grid_item__wrapper pe_none">
                                    <div class="cat_grid_item__title">Watch{{recommend_style.home_recommends[2].home_recommend_id}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RecommendStyle2",
    props: ['recommend_style'],
}
</script>

<style scoped>

</style>