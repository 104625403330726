<template>
    <div id="nt_wrapper">

        <Header/>

        <div id="nt_content">

            <!-- main slide -->
            <div class="nt_section type_slideshow type_carousel ">
                <div class="slideshow-wrapper nt_full se_height_cus_h nt_first">
                    <div class="fade_flick_1 slideshow row no-gutters equal_nt nt_slider js_carousel prev_next_0 btn_owl_1 dot_owl_2 dot_color_1 btn_vi_2" data-flickity='{ "fade":0,"cellAlign": "center","imagesLoaded": 0,"lazyLoad": 0,"freeScroll": 0,"wrapAround": true,"autoPlay" : 10000,"pauseAutoPlayOnHover" : true, "rightToLeft": false,"prevNextButtons": 1,"percentPosition": 1,"pageDots": true, "contain" : 1,"adaptiveHeight" : 1,"dragThreshold" : 5,"percentPosition": 1 }'>

                        <!-- first slide -->
                        <div v-for="(dt, index) in banner_list" :key="index" class="col-12 slideshow__slide">
                            <div class="oh position-relative nt_img_txt bg-black--transparent">
                                <div class="js_full_ht4 img_slider_block kalles-slide-element__pdb-600">
                                    <div class="bg_rp_norepeat bg_sz_cover lazyload item__position center img_zoom pa l__0 t__0 r__0 b__0" :data-bgset="dt.img.url"></div>
                                </div>
                                <div class="caption-wrap caption-w-1 pe_none z_100 tl_md tl">
                                    <div v-if="dt.position > 0" :class="['pa_txts caption kalles-caption-layout-01 kalles-caption--midle-left-', ('home-banner-position-' + dt.position)]">
                                        <div class="left_right">
                                            <h4 class="kalles-caption-layout-01__subtitle mg__0 lh__1" :style="dt.slogan_color ? ('color:' + dt.slogan_color) : ''">{{dt.slogan}}</h4>
                                            <h3 class="kalles-caption-layout-01__title mg__0 lh__1" :style="dt.title_color ? ('color:' + dt.title_color) : ''">{{dt.title}}</h3>
                                            <a class="kalles-caption-layout-01__button kalles-button--square slt4_btn button pe_auto round_false btn_icon_false" :href="dt.url" :target="dt.blank ? '_blank' : '_self'" :style="[(dt.but_background_color ? ('background-color:' + dt.but_background_color) : ''), (dt.but_background_color ? ('border-color:' + dt.but_background_color) : ''), (dt.but_color ? ('color:' + dt.but_color) : '')]">{{dt.but}}</a>
                                        </div>
                                    </div>
                                </div>
                                <a :href="dt.url" :class="['pa t__0 l__0 b__0 r__0', {'pe_none': dt.position > 0}]" :target="dt.blank ? '_blank' : '_self'"></a>
                            </div>
                        </div>
                        <!-- end first slide -->

                        <!-- second slide -->
                        <div v-if="false" class="col-12 slideshow__slide">
                            <div class="oh position-relative nt_img_txt bg-black--transparent">
                                <div class="js_full_ht4 img_slider_block kalles-slide-element__pdb-600">
                                    <div class="bg_rp_norepeat bg_sz_cover lazyload item__position center center img_zoom pa l__0 t__0 r__0 b__0" data-bgset="/img/ls/b-002.jpg"></div>
                                </div>
                                <div class="caption-wrap caption-w-1 pe_none z_100 tr_md tl">
                                    <div class="pa_txts caption kalles-caption-layout-01 kalles-caption--midle-right">
                                        <div class="right_left">
                                            <h4 class="kalles-caption-layout-01__subtitle mg__0 lh__1">NEW SEASON</h4>
                                            <h3 class="kalles-caption-layout-01__title mg__0 lh__1">Lookbook Collection</h3>
                                            <a class="kalles-caption-layout-01__button kalles-button--square slt4_btn button pe_auto round_false btn_icon_false" href="shop-filter-options.html">Explore Now</a></div>
                                    </div>
                                </div>
                                <a href="shop.html" class="pa t__0 l__0 b__0 r__0 pe_none"></a>
                            </div>
                        </div>
                        <!-- end second slide -->

                        <!-- third slide -->
                        <div v-if="false" class="col-12 slideshow__slide">
                            <div class="oh position-relative nt_img_txt bg-black--transparent">
                                <div class="js_full_ht4 img_slider_block kalles-slide-element__pdb-600">
                                    <div class="bg_rp_norepeat bg_sz_cover lazyload item__position center center img_tran_ef pa l__0 t__0 r__0 b__0" data-bgset="/img/ls/b-003.jpg"></div>
                                </div>
                                <div class="caption-wrap caption-w-1 pe_none z_100 tl_md tl">
                                    <div class="pa_txts caption kalles-caption-layout-01 kalles-caption--midle-left">
                                        <div class="left_right">
                                            <h4 class="kalles-caption-layout-01__subtitle mg__0 lh__1">SUMMER SALE</h4>
                                            <h3 class="kalles-caption-layout-01__title mg__0 lh__1">Save up to 70%</h3>
                                            <a class="kalles-caption-layout-01__button kalles-button--square slt4_btn button pe_auto round_false btn_icon_false" href="shop-filter-options.html">Explore Now</a></div>
                                    </div>
                                </div>
                                <a href="shop.html" class="pa t__0 l__0 b__0 r__0 pe_none"></a>
                            </div>
                        </div>
                        <!-- end third slide -->

                    </div>
                </div>
            </div>
            <!-- end main slide -->

            <!-- top category banner -->
            <template v-for="(dt, index) in recommend_style_list" :key="index">
                <RecommendStyle1 v-if="index == 0" :recommend_style="dt" />
                <RecommendStyle2 v-else-if="index == 1" :recommend_style="dt" />
                <RecommendStyle3 v-else-if="index == 2" :recommend_style="dt" />
                <RecommendStyle4 v-else-if="index == 3" :recommend_style="dt" />
                <RecommendStyle5 v-else-if="index == 4" :recommend_style="dt" />
                <RecommendStyle6 v-else-if="index == 5" :recommend_style="dt" />
            </template>
            <!-- end top category banner -->

            <!-- featured collection -->
            <div class="nt_section type_featured_collection tp_se_cdt">
                <div class="kalles-otp-01__feature container">
                    <div class="wrap_title des_title_2">
                        <h3 class="section-title tc position-relative flex fl_center al_center fs__24 title_2"><span class="mr__10 ml__10">耳背式助听器</span></h3>
                        <span class="dn tt_divider"><span></span><i class="dn clprfalse title_2 la-gem"></i><span></span></span>
                        <span class="section-subtitle db tc sub-title">推荐的部分产品</span>
                    </div>
                    <div class="products nt_products_holder row fl_center row_pr_1 cdt_des_5 round_cd_true nt_cover ratio_nt position_8 space_30">
                        <template v-for="(dt, index) in product_list_2" :key="index">
                            <div v-if="dt.product_category_id == 2" class="col-lg-3 col-md-3 col-6 pr_animated done mt__30 pr_grid_item product nt_pr desgin__1">
                                <div class="product-inner pr">
                                    <div class="product-image position-relative oh lazyload">
                                        <span v-if="dt.mark_title" class="tc nt_labels pa pe_none cw"><span class="nt_label" :style="dt.mark_color ? ('background-color:' + dt.mark_color) : ''">{{dt.mark_title}}</span></span>
                                        <a class="d-block" :href="'/product/item/' + dt.product_id">
                                            <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyload padding-top__100" :data-bgset="dt.scene_url"></div>
                                        </a>
                                        <div class="hover_img pa pe_none t__0 l__0 r__0 b__0 op__0">
                                            <div class="pr_lazy_img back-img pa nt_bg_lz lazyload padding-top__100" :data-bgset="dt.scene_url"></div>
                                        </div>
                                        <div v-if="false" class="nt_add_w ts__03 pa "><a href="#" class="wishlistadd cb chp ttip_nt tooltip_right"><span class="tt_txt">Add to Wishlist</span><i class="facl facl-heart-o"></i></a></div>
                                        <div v-if="false" class="hover_button op__0 tc pa flex column ts__03"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="#"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="#" class="pr pr_atc cd br__40 bgw tc dib js__qs cb chp ttip_nt tooltip_top_left"><span class="tt_txt">Quick Shop</span><i class="iccl iccl-cart"></i><span>Quick Shop</span></a></div>
                                        <div v-if="false" class="product-attr pa ts__03 cw op__0 tc">
                                            <p class="truncate mg__0 w__100">XS, S, M</p>
                                        </div>
                                    </div>
                                    <div class="product-info mt__15">
                                        <h3 class="product-title position-relative fs__14 mg__0 fwm"><a class="cd chp" :href="'/product/item/' + dt.product_id">{{dt.title}}</a></h3>
                                        <span v-if="website.show_price" class="price dib mb__5">
                                            <span class="fs__10">￥</span>
                                            <span v-if="dt.min_price == dt.max_price">{{dt.min_price.toFixed(2)}}</span>
                                            <span v-else>{{dt.min_price.toFixed(2)}}-{{dt.max_price.toFixed(2)}}</span>
                                            <del v-if="false">￥{{dt.market_price.toFixed(2)}}</del>
                                            <ins v-if="false">￥{{dt.price.toFixed(2)}}</ins>
                                        </span>
                                        <div class="swatch__list_js swatch__list lh__1 nt_swatches_on_grid">
                                            <template v-for="(dt2, index2) in dt.sku_list" :key="index2">
                                                <span v-if="dt2.img" :data-bgset="dt2.img.url" class="nt_swatch_on_bg swatch__list--item pr ttip_nt tooltip_top_right"><span class="tt_txt">{{dt2.title}}</span><span class="swatch__value bg_color_white-cream lazyload" :data-bgset="dt2.img.url"></span></span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="products-footer tc mt__40"><a class="se_cat_lm pr nt_cat_lm- button button_default br_rd_true btn_icon_false" href="/product/list/2">更多产品</a></div>
                </div>
            </div>
            <!-- end featured collection -->

            <!-- banner -->
            <div v-if="ad_style_list && ad_style_list.length > 0" class="kalles-section nt_section type_banner">
                <div class="kalles-otp-01__promotion container">
                    <div class="mt__30 nt_banner_holder row equal_nt auto hoverz_true cat_space_30 txt_shadow_false">
                        <div v-for="(dt, index) in ad_style_list[0].home_ads" :key="index" class="cat_space_item col-lg-6 col-md-6 col-12">
                            <div class="nt_promotion oh pr middle center banner_design_1">
                                <a :href="dt.url" target="_blank" class="d-block">
                                    <div class="nt_bg_lz lazyload item__position center" :data-bgset="dt.img_url ? dt.img_url : '/img/placeholders/570-300.jpg'"></div>
                                </a>
                                <div v-if="false" class="pa t__0 l__0 b__0 r__0 flex column shadow_wrap tc fl_center pe_none">
                                    <h3 class="fwm fs__24 mg__0 mb__5 ls__1 lh__1">LOOKBOOK 2021</h3>
                                    <h4 class="mg__0 fs__14">MAKE LOVE THIS LOOK</h4>
                                </div>
                            </div>
                        </div>
                        <div v-if="false" class="cat_space_item col-lg-6 col-md-6 col-12">
                            <div class="nt_promotion oh pr middle center banner_design_2">
                                <a href="#" class="d-block">
                                    <div class="nt_bg_lz lazyload item__position center" data-bgset="/images/home-01/bn-06.jpg"></div>
                                </a>
                                <div class="pa t__0 l__0 b__0 r__0 flex column shadow_wrap tc fl_center pe_none">
                                    <h3 class="fwm fs__18 mg__0 mb__5 ls__1">SUMMER SALE</h3>
                                    <h4 class="mg__0 fs__50 lh__1">UP TO 70%</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end banner -->

            <!-- bestseller products-->
            <div class="kalles-section nt_section type_featured_collection tp_se_cdt">
                <div class="kalles-otp-01__featured-collection-2 container">
                    <div class="wrap_title  des_title_2">
                        <h3 class="section-title tc pr flex fl_center al_center fs__24 title_2"><span class="mr__10 ml__10">盒式助听器</span></h3>
                        <span class="dn tt_divider"><span></span><i class="dn clprfalse title_2 la-gem"></i><span></span></span>
                        <span class="section-subtitle db tc sub-title">推荐的部分产品</span>
                    </div>
                    <div class="products nt_products_holder row fl_center row_pr_1 cdt_des_5 round_cd_true nt_cover ratio_nt position_8 space_30">
                        <template v-for="(dt, index) in product_list_1" :key="index">
                            <div v-if="dt.product_category_id == 1" class="col-lg-3 col-md-3 col-6 pr_animated done mt__30 pr_grid_item product nt_pr desgin__1">
                                <div class="product-inner pr">
                                    <div class="product-image position-relative oh lazyload">
                                        <span v-if="dt.mark_title" class="tc nt_labels pa pe_none cw"><span class="nt_label" :style="dt.mark_color ? ('background-color:' + dt.mark_color) : ''">{{dt.mark_title}}</span></span>
                                        <a class="d-block" :href="'/product/item/' + dt.product_id">
                                            <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyload padding-top__100" :data-bgset="dt.scene_url"></div>
                                        </a>
                                        <div class="hover_img pa pe_none t__0 l__0 r__0 b__0 op__0">
                                            <div class="pr_lazy_img back-img pa nt_bg_lz lazyload padding-top__100" :data-bgset="dt.scene_url"></div>
                                        </div>
                                        <div v-if="false" class="nt_add_w ts__03 pa "><a href="#" class="wishlistadd cb chp ttip_nt tooltip_right"><span class="tt_txt">Add to Wishlist</span><i class="facl facl-heart-o"></i></a></div>
                                        <div v-if="false" class="hover_button op__0 tc pa flex column ts__03"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="#"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="#" class="pr pr_atc cd br__40 bgw tc dib js__qs cb chp ttip_nt tooltip_top_left"><span class="tt_txt">Quick Shop</span><i class="iccl iccl-cart"></i><span>Quick Shop</span></a></div>
                                        <div v-if="false" class="product-attr pa ts__03 cw op__0 tc">
                                            <p class="truncate mg__0 w__100">XS, S, M</p>
                                        </div>
                                    </div>
                                    <div class="product-info mt__15">
                                        <h3 class="product-title position-relative fs__14 mg__0 fwm"><a class="cd chp" :href="'/product/item/' + dt.product_id">{{dt.title}}</a></h3>
                                        <span v-if="website.show_price" class="price dib mb__5">
                                            <span class="fs__10">￥</span>
                                            <span v-if="dt.min_price == dt.max_price">{{dt.min_price.toFixed(2)}}</span>
                                            <span v-else>{{dt.min_price.toFixed(2)}}-{{dt.max_price.toFixed(2)}}</span>
                                            <del v-if="false">￥{{dt.market_price.toFixed(2)}}</del>
                                            <ins v-if="false">￥{{dt.price.toFixed(2)}}</ins>
                                        </span>
                                        <div class="swatch__list_js swatch__list lh__1 nt_swatches_on_grid">
                                            <template v-for="(dt2, index2) in dt.sku_list" :key="index2">
                                                <span v-if="dt2.img" :data-bgset="dt2.img.url" class="nt_swatch_on_bg swatch__list--item pr ttip_nt tooltip_top_right"><span class="tt_txt">{{dt2.title}}</span><span class="swatch__value bg_color_white-cream lazyload" :data-bgset="dt2.img.url"></span></span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="products-footer tc mt__40"><a class="se_cat_lm pr nt_cat_lm- button button_default br_rd_true btn_icon_false" href="/product/list/1">更多产品</a></div>
                </div>
            </div>
            <!-- end bestseller products-->

            <!--blog post-->
            <div v-if="false" class="kalles-section kalles-section_type_featured_blog nt_section type_featured_blog type_carousel">
                <div class="container">
                    <div class="wrap_title mb__30 des_title_2">
                        <h3 class="section-title tc pr flex fl_center al_center fs__24 title_2"><span class="mr__10 ml__10">LATES FROM BLOG</span></h3>
                        <span class="dn tt_divider"><span></span><i class="dn clprfalse title_2 la-pencil-alt"></i><span></span></span><span class="section-subtitle db tc sub-title">The freshest and most exciting news</span></div>
                    <div class="articles art_des1 nt_products_holder row nt_cover ratio4_3 position_8 equal_nt js_carousel nt_slider prev_next_1 btn_owl_1 dot_owl_1 dot_color_1 btn_vi_1" data-flickity='{"imagesLoaded": 0,"adaptiveHeight": 1, "contain": 1, "groupCells": "100%", "dragThreshold" : 5, "cellAlign": "left","wrapAround": false,"prevNextButtons": true,"percentPosition": 1,"pageDots": false, "autoPlay" : 0, "pauseAutoPlayOnHover" : true, "rightToLeft": false }'>
                        <article class="post_nt_loop post_1 col-lg-4 col-md-4 col-12 pr_animated done mb__40"><a class="mb__15 db pr oh" href="blog-left-sidebar.html">
                            <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/blog/blog-01.jpg"></div>
                        </a>
                            <div class="post-info mb__10">
                                <h4 class="mg__0 fs__16 mb__5 ls__0"><a class="cd chp open" href="blog-post-with-instagram-shop.html">Spring – Summer Trending 2020</a></h4>
                                <span class="post-author mr__5">By <span class="cd">admin</span></span><span class="post-time">on <span class="cd">
                                <time datetime="2020-04-06T02:22:00Z">April 6, 2020</time>
                                </span></span></div>
                            <div class="post-content">Typography is the work of typesetters, compositors, typographers, graphic designers, art directors, manga artists, ...</div>
                        </article>
                        <article class="post_nt_loop post_1 col-lg-4 col-md-4 col-12 pr_animated done mb__40"><a class="mb__15 db pr oh" href="blog-left-sidebar.html">
                            <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/blog/blog-02.jpg"></div>
                        </a>
                            <div class="post-info mb__10">
                                <h4 class="mg__0 fs__16 mb__5 ls__0"><a class="cd chp open" href="blog-post-with-instagram-shop.html">The Easiest Way to Break Out on Top</a></h4>
                                <span class="post-author mr__5">By <span class="cd">admin</span></span><span class="post-time">on <span class="cd">
                                <time datetime="2020-04-06T02:22:00Z">April 6, 2020</time>
                                </span></span></div>
                            <div class="post-content">Typography is the work of typesetters, compositors, typographers, graphic designers, art directors, manga artists, ...</div>
                        </article>
                        <article class="post_nt_loop post_1 col-lg-4 col-md-4 col-12 pr_animated done mb__40"><a class="mb__15 db pr oh" href="blog-left-sidebar.html">
                            <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/blog/blog-03.jpg"></div>
                        </a>
                            <div class="post-info mb__10">
                                <h4 class="mg__0 fs__16 mb__5 ls__0"><a class="cd chp open" href="blog-post-with-instagram-shop.html">Style for couple in Weeding season</a></h4>
                                <span class="post-author mr__5">By <span class="cd">admin</span></span><span class="post-time">on <span class="cd">
                                <time datetime="2020-04-06T02:22:00Z">April 6, 2020</time>
                                </span></span></div>
                            <div class="post-content">Typography is the work of typesetters, compositors, typographers, graphic designers, art directors, manga artists, ...</div>
                        </article>
                    </div>
                </div>
            </div>
            <!--end blog post-->

            <!--instagram-->
            <div v-if="false" class="kalles-section nt_section kalles-section__type_instagram_shop type_instagram_shop type_pin_owl">
                <div class="nt_full">
                    <div class="wrap_title mb__20 des_title_2">
                        <h3 class="section-title tc pr flex fl_center al_center fs__24 title_2"><span class="mr__10 ml__10">@ FOLLOW US ON INSTAGRAM</span></h3>
                        <span class="dn tt_divider"><span></span><i class="dn clprfalse title_2 la-gem"></i><span></span></span><span class="section-subtitle db tc sub-title"></span></div>
                    <div class="ins_shop_wrap row ins_rounded_0 ins_spaces_0 nt_cover ratio1_1 position_8 equal_nt js_carousel nt_slider prev_next_0 btn_owl_1 dot_owl_1 dot_color_1 btn_vi_1" data-flickity='{"imagesLoaded": 0,"adaptiveHeight": 1, "contain": 1, "groupCells": "100%", "dragThreshold" : 5, "cellAlign": "left","wrapAround": false,"prevNextButtons": true,"percentPosition": 1,"pageDots": false, "autoPlay" : 0, "pauseAutoPlayOnHover" : true, "rightToLeft": false }'>
                        <div class="pin__wr_js col_ins col-lg-2 col-md-3 col-6 pr_animated done item pr oh"><a href="#" class="js_add_qv hotspot_ins pa op__0 dark spin-position__01"><span>1</span></a>
                            <div class="wrap_ins_img db pr oh">
                                <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/instagram/ins1_14.jpg"></div>
                            </div>
                        </div>
                        <div class="pin__wr_js col_ins col-lg-2 col-md-3 col-6 pr_animated done item pr oh"><a href="#" class="js_add_qv hotspot_ins pa op__0 dark spin-position__02"><span>1</span></a>
                            <div class="wrap_ins_img db pr oh">
                                <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/instagram/ins1_8.jpg"></div>
                            </div>
                        </div>
                        <div class="pin__wr_js col_ins col-lg-2 col-md-3 col-6 pr_animated done item pr oh"><a href="#" class="js_add_qv hotspot_ins pa op__0 dark spin-position__03"><span>1</span></a><a href="#" class="js_add_qv hotspot_ins pa op__0 dark spin-position__04"><span>2</span></a>
                            <div class="wrap_ins_img db pr oh">
                                <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/instagram/ins1_1.jpg"></div>
                            </div>
                        </div>
                        <div class="pin__wr_js col_ins col-lg-2 col-md-3 col-6 pr_animated done item pr oh"><a href="#" class="js_add_qv hotspot_ins pa op__0 dark spin-position__05"><span>1</span></a>
                            <div class="wrap_ins_img db pr oh">
                                <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/instagram/ins1_6.jpg"></div>
                            </div>
                        </div>
                        <div class="pin__wr_js col_ins col-lg-2 col-md-3 col-6 pr_animated done item pr oh"><a href="#" class="js_add_qv hotspot_ins pa op__0 dark spin-position__06"><span>1</span></a>
                            <div class="wrap_ins_img db pr oh">
                                <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/instagram/ins1_5.jpg"></div>
                            </div>
                        </div>
                        <div class="pin__wr_js col_ins col-lg-2 col-md-3 col-6 pr_animated done item pr oh"><a href="#" class="js_add_qv hotspot_ins pa op__0 dark spin-position__07"><span>1</span></a>
                            <div class="wrap_ins_img db pr oh">
                                <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/instagram/ins1_4.jpg"></div>
                            </div>
                        </div>
                        <div class="pin__wr_js col_ins col-lg-2 col-md-3 col-6 pr_animated done item pr oh"><a href="#" class="js_add_qv hotspot_ins pa op__0 dark spin-position__08"><span>1</span></a>
                            <div class="wrap_ins_img db pr oh">
                                <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/instagram/ins1_2.jpg"></div>
                            </div>
                        </div>
                        <div class="pin__wr_js col_ins col-lg-2 col-md-3 col-6 pr_animated done item pr oh"><a href="#" class="js_add_qv hotspot_ins pa op__0 dark spin-position__09"><span>1</span></a>
                            <div class="wrap_ins_img db pr oh">
                                <div class="lazyload nt_bg_lz pr_lazy_img" data-bgset="/images/instagram/ins1_13.jpg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end instagram-->

            <!--shipping info-->
            <div v-if="false" class="kalles-section nt_section type_shipping kalles-section__type_shipping">
                <div class="container">
                    <div class="row fl_wrap fl_wrap_md oah use_border_false">
                        <div class="col-12 col-md-6 col-lg-3 mb__25 bl_1581530479619-0">
                            <div class="nt_shipping nt_icon_deafult tl row no-gutters al_center_">
                                <div class="col-auto icon medium csi"><i class="pegk pe-7s-car"></i></div>
                                <div class="col content">
                                    <h3 class="title cd fs__14 mg__0 mb__5">FREE SHIPPING</h3>
                                    <p class="mg__0">Free shipping on all US order or order above $100</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb__25 bl_1581530479619-1">
                            <div class="nt_shipping nt_icon_deafult tl row no-gutters al_center_">
                                <div class="col-auto icon medium csi"><i class="pegk pe-7s-help2"></i></div>
                                <div class="col content">
                                    <h3 class="title cd fs__14 mg__0 mb__5">SUPPORT 24/7</h3>
                                    <p class="mg__0">Contact us 24 hours a day, 7 days a week</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb__25 bl_1581530479619-2">
                            <div class="nt_shipping nt_icon_deafult tl row no-gutters al_center_">
                                <div class="col-auto icon medium csi"><i class="pegk pe-7s-refresh"></i></div>
                                <div class="col content">
                                    <h3 class="title cd fs__14 mg__0 mb__5">30 DAYS RETURN</h3>
                                    <p class="mg__0">Simply return it within 30 days for an exchange.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb__25 bl_1581530479619-3">
                            <div class="nt_shipping nt_icon_deafult tl row no-gutters al_center_">
                                <div class="col-auto icon medium csi"><i class="pegk pe-7s-door-lock"></i></div>
                                <div class="col content">
                                    <h3 class="title cd fs__14 mg__0 mb__5">100% PAYMENT SECURE</h3>
                                    <p class="mg__0">We ensure secure payment with PEV</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end shipping info-->

        </div>

        <Footer/>

    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import RecommendStyle1 from './home/RecommendStyle1.vue'
import RecommendStyle2 from './home/RecommendStyle2.vue'
import RecommendStyle3 from './home/RecommendStyle3.vue'
import RecommendStyle4 from './home/RecommendStyle4.vue'
import RecommendStyle5 from './home/RecommendStyle5.vue'
import RecommendStyle6 from './home/RecommendStyle6.vue'
import {getCurrentInstance, onMounted, onUnmounted, reactive, ref, inject} from "vue";
import {listRecommendStyleHomeV1Api} from "@/api/home/recommend-style";
import {listAdStyleHomeV1Api} from "@/api/home/ad-style";
import {listBannerHomeV1Api} from "@/api/home/banner";
import {homeProductV1Api} from "@/api/product/product";

export default {
    name: 'Home',
    components: {Header, Footer, RecommendStyle1, RecommendStyle2, RecommendStyle3, RecommendStyle4, RecommendStyle5, RecommendStyle6},
    setup() {
        const {proxy} = getCurrentInstance();

        const website = inject('website');

        const options = reactive({
            title: '首页',
            name: proxy.$route.name,
            url: proxy.$route.path,
        });

        const recommend_style_list = ref([]);
        const apiRecommendStyleHomeList = () => {
            return new Promise(function (resolve) {
                listRecommendStyleHomeV1Api({}).then(res => {
                    if (res.data.code == 0) {
                        recommend_style_list.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const ad_style_list = ref([]);
        const apiAdStyleHomeList = () => {
            return new Promise(function (resolve) {
                listAdStyleHomeV1Api({}).then(res => {
                    if (res.data.code == 0) {
                        ad_style_list.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const banner_list = ref([]);
        const apiBannerList = () => {
            return new Promise(function (resolve) {
                listBannerHomeV1Api({page: 1, page_size: 10}).then(res => {
                    if (res.data.code == 0) {
                        banner_list.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const product_list_2 = ref([]);
        const apiHomeProductList2 = () => {
            return new Promise(function (resolve) {
                homeProductV1Api({product_category_id: 2, have_sku_list: false, page: 0, page_size: 8}).then(res => {
                    if (res.data.code == 0) {
                        product_list_2.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        const product_list_1 = ref([]);
        const apiHomeProductList1 = () => {
            return new Promise(function (resolve) {
                homeProductV1Api({product_category_id: 1, have_sku_list: false, page: 0, page_size: 8}).then(res => {
                    if (res.data.code == 0) {
                        product_list_1.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        // 初始数据加载
        Promise.all([apiBannerList(), apiHomeProductList2(), apiHomeProductList1(), apiRecommendStyleHomeList(), apiAdStyleHomeList()]).then(() => {

            // apiSingleList().then(() => {
            //     mainNotyConfirm.close();
            // });
        });

        const loadScript = (url) => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            document.getElementsByTagName("body")[0].appendChild(script);
        }
        onMounted(() => {
            document.getElementsByTagName('body')[0].className = 'kalles-template header_full_true des_header_3 css_scrollbar lazy_icons btnt4_style_2 zoom_tp_2 template-index kalles_toolbar_true hover_img2 swatch_style_rounded swatch_list_size_small label_style_rounded wrapper_full_width hide_scrolld_true lazyload'

            setTimeout(function() {
                loadScript("/js/flickity.pkgd.min.js");
                loadScript("/js/interface.js");
            }, 500);
        })
        onUnmounted(() => {
            document.body.removeAttribute('class')
        })

        return {options, website, banner_list, product_list_2, product_list_1, recommend_style_list, ad_style_list}
    },
}
</script>
